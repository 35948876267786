<template>
  <div class="sect_info_box_2">
    <ul class="tab">
      <li class="on">
        <router-link to="" @click.native="openTab('all', $event)"
          >전체</router-link
        >
      </li>
      <li>
        <router-link to="" @click.native="openTab('reType1', $event)"
          >답변완료</router-link
        >
      </li>
      <li>
        <router-link to="" @click.native="openTab('reType0', $event)"
          >미답변</router-link
        >
      </li>
      <li>
        <router-link to="" @click.native="openTab('my', $event)"
          >내 Q&A</router-link
        >
      </li>
      <li>
        <router-link to="" @click.native="openTab('write', $event)"
          >질문하기</router-link
        >
      </li>
    </ul>
    <template v-if="isWrite">
      <div class="text_box"><textarea v-model="content"></textarea></div>
      <div class="send_box">
        <div class="left">
          <label
            ><input
              type="radio"
              name="open_yn"
              value="0"
              v-model="openYn"
            />공개</label
          >
          <label
            ><input
              type="radio"
              name="open_yn"
              value="1"
              v-model="openYn"
            />비공개</label
          >
        </div>
        <div class="right">
          <router-link to="" class="gray" @click.native="cancelWirte"
            >취소</router-link
          >
          <router-link to="" class="black" @click.native="writeQna"
            >등록하기</router-link
          >
        </div>
      </div>
    </template>

    <div class="qna_list" v-for="list in listArray" :key="list.seq">
      <div class="qna_box">
        <div class="ques">
          <ul class="top">
            <li>
              {{ list.mid }}<span>{{ day(list.r_date) }}</span>
            </li>
            <li>{{ list.re_mtype === "o" ? "미답볍" : "답변완료" }}</li>
            <li
              v-if="list.mseq === myInfo.member_seq"
              @click="modify(list.seq)"
            >
              수정
            </li>
            <li
              v-if="list.mseq === myInfo.member_seq"
              @click="deleteQna(list.seq)"
            >
              삭제
            </li>
            <li
              v-if="
                list.mseq !== myInfo.member_seq &&
                $store.state.member.isLogin &&
                parseInt(list.hidden) !== 1
              "
            >
              <router-link to="" @click.native="openDeclaration(list.seq)"
                >신고</router-link
              >
            </li>
            <li>
              <router-link
                to=""
                @click.native="openAnswer(list.re_mtype, $event)"
                ><img src="@/assets/images/sub/my_down_ico.png" alt=""
              /></router-link>
            </li>
          </ul>
          <div class="declaration_pop qna" :id="`declaration_qna_${list.seq}`">
            <!--신고하기팝업-->
            <h4>신고할 내용을 입력해주세요</h4>
            <div class="sel_type">
              <textarea v-model="etcContent"></textarea>
            </div>
            <div class="btn">
              <router-link
                to=""
                class="black"
                @click.native="reportSet(list.seq)"
                >신고</router-link
              ><router-link to="" class="gray" @click.native="declaration"
                >취소</router-link
              >
            </div>
          </div>
          <div class="bottom" :id="`org_id_${list.seq}`" v-html="list.contents">
            {{ list.contents }}
          </div>
          <div
            :id="`modify_id_${list.seq}`"
            class="modify_list"
            style="display: none"
          >
            <div class="text_box">
              <textarea :value="textAreraBr(list.contents)"></textarea>
            </div>
            <div class="send_box">
              <div class="left">
                <label
                  ><input
                    type="radio"
                    :name="`open_yn_${list.seq}`"
                    value="0"
                    :checked="parseInt(list.hidden) === 0"
                  />공개</label
                >
                <label
                  ><input
                    type="radio"
                    :name="`open_yn_${list.seq}`"
                    value="1"
                    :checked="parseInt(list.hidden) === 1"
                  />비공개</label
                >
              </div>
              <div class="right">
                <router-link
                  to=""
                  class="gray"
                  @click.native="cancelModify(list.seq)"
                  >취소</router-link
                >
                <router-link
                  to=""
                  class="black"
                  @click.native="modifyQna(list.seq, $event)"
                  >수정하기</router-link
                >
              </div>
            </div>
          </div>
        </div>
        <div
          class="answ"
          v-if="list.re_contents"
          :style="list.re_contents ? 'display:none' : ''"
        >
          <div class="top" v-html="list.re_contents">
            {{ list.re_contents }}
          </div>
          <ul class="bottom">
            <li>
              {{ getAnswerType(list.re_mtype)
              }}<span>{{ day(list.re_date) }}</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="qna_list" v-if="listArray.length <= 0">
      <div class="qna_box none_data">등록된 Q&A 가 없습니다.</div>
    </div>
    <div class="rn_more" v-if="parseInt(totalPage) > parseInt(page)">
      <router-link to="" @click.native="getQnaList()"
        >{{ parseInt(limit) }}개 더보기<img
          src="@/assets/images/sub/my_down_ico.png"
          alt=""
      /></router-link>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      page: 0,
      where: "all",
      openYn: "0",
      isWrite: false,
      content: null,
      url: this.$route.path,
      etcContent: "",
    };
  },
  props: {
    goodsSeq: {
      type: String,
      default: "",
    },
  },
  mounted() {
    this.$nextTick(function () {
      this.getQnaList(true);
    });
  },
  computed: {
    ...mapState("shop", ["result", "msg", "listArray", "totalPage", "limit"]),
    ...mapState("mypage", ["myInfo"]),
  },
  methods: {
    openDeclaration(seq) {
      const popUp = document.querySelector(`#declaration_qna_${seq}`);
      const popUpAll = document.querySelectorAll(`.declaration_pop.qna`);
      [...popUpAll].map((pop) => (pop.style.display = "none"));
      popUp.style.display = "block";
    },
    declaration() {
      this.etcContent = "";
      const popUpAll = document.querySelectorAll(`.declaration_pop.qna`);
      [...popUpAll].map((pop) => (pop.style.display = "none"));
    },
    async reportSet(seq) {
      if (!this.etcContent || this.etcContent.replace(/\s/g, "").length <= 0) {
        this.etcContent = null;
        this.$toast.default("신고할 내용을 입력해주세요.");
        return false;
      }

      await this.$store.dispatch("mypage/reportSet", {
        report_type: "qna",
        report_seq: this.goodsSeq,
        qna_seq: seq,
        report_reaseon: "etc",
        content: `(${seq}) ${this.etcContent}`,
      });
      this.etcContent = null;
      this.$toast.default(this.$store.state.mypage.msg);
      this.declaration();
    },
    textAreraBr(str) {
      return str.replace(/<br ?\/?>/g, "");
    },
    modify(seq) {
      const hideList = [...document.querySelectorAll(".modify_list")];
      hideList.map((li) => (li.style.display = "none"));
      [...document.querySelectorAll(".bottom")].map(
        (bottom) => (bottom.style.display = "block")
      );
      document.querySelector(`#org_id_${seq}`).style.display = "none";
      document.querySelector(`#modify_id_${seq}`).style.display = "block";
    },
    cancelModify(seq) {
      const orgContent = this.listArray.find(
        (list) => parseInt(list.seq) === parseInt(seq)
      );
      const hideList = [...document.querySelectorAll(".modify_list")];
      hideList.map((li) => (li.style.display = "none"));
      document.querySelector(`#modify_id_${seq}`).style.display = "none";
      document.querySelector(`#org_id_${seq}`).style.display = "block";
      document.querySelector(`#modify_id_${seq}  textarea`).value =
        this.textAreraBr(orgContent.contents);
    },
    getAnswerType(type) {
      if (type === "r") {
        return "관리자";
      } else if (type === "p") {
        return "판매자";
      } else {
        return "시스템";
      }
    },
    openAnswer(answer, event) {
      const el = event.target.closest("div").nextElementSibling;
      if (answer !== "o") {
        if (el.style.display === "none") {
          event.target.closest("li").querySelector("img").style =
            "transform:rotate(180deg)";
          //   event.target.querySelector("img").style = "transform:rotate(180deg)";
          el.style.display = "block";
        } else {
          event.target.closest("li").querySelector("img").style =
            "transform:rotate(0deg)";
          el.style.display = "none";
        }
      }
    },
    cancelWirte() {
      this.content = null;
      const menuEl = [...document.querySelector(".tab").querySelectorAll("li")];
      menuEl.map((menu) => menu.classList.remove("on"));
      menuEl[0].classList.add("on");
      this.isWrite = false;
      this.where = "all";
    },
    openTab(type, event) {
      const menuEl = [...event.target.closest("ul").querySelectorAll("li")];
      menuEl.map((menu) => menu.classList.remove("on"));
      event.target.closest("li").classList.add("on");
      if (type === "write") {
        const hideList = [...document.querySelectorAll(".modify_list")];
        hideList.map((li) => (li.style.display = "none"));
        [...document.querySelectorAll(".bottom")].map(
          (bottom) => (bottom.style.display = "block")
        );
        this.where = type;
        if (this.$store.state.member.isLogin) {
          this.isWrite = true;
        } else {
          this.needsLogin();
        }
      } else {
        this.isWrite = false;
        this.where = type;
        if (type == "my") {
          if (this.$store.state.member.isLogin) {
            this.getQnaList(true);
          } else {
            this.needsLogin();
          }
        } else {
          this.getQnaList(true);
        }
      }
    },
    needsLogin() {
      this.$confirm("로그인이 필요합니다.")
        .then(() => {
          const urlCheck = this.url.split("/");
          let check = urlCheck.some((str) => str === "qna");
          this.$store.dispatch("common/setReferer", {
            path: !check ? `${this.url}/qna` : this.url,
          });
          this.$router.push("/member/login");
          return false;
        })
        .catch(() => {
          return false;
        });
    },
    day(str) {
      return this.$moment(str).format("YYYY.MM.DD");
    },
    async getQnaList(reset = false) {
      if (reset) {
        this.page = 0;
      }
      await this.$store.dispatch("shop/getQnaList", {
        page: this.page,
        where: this.where,
        goods_seq: this.goodsSeq,
        reset,
      });
      if (!this.result) {
        this.$toast.default(this.msg);
      }
      this.page++;
    },
    async writeQna() {
      if (!this.content || this.content.replace(/\s/g, "").length <= 0) {
        this.content = "";
        this.$toast.default("질문 내용을 작성해 주세요.");
        return false;
      }

      let formData = new FormData();

      formData.append("mode", "board_write"); //기본값
      formData.append("popup", "1"); //기본값
      formData.append("board_id", "goods_qna"); //기본값
      formData.append("iframe", "1"); //기본값
      formData.append("category", ""); //기본값
      formData.append("subject", "램 상품 문의 입니다."); //기본값
      formData.append("goods_seq", this.goodsSeq);
      formData.append("name", this.myInfo.user_name);
      formData.append("oldpw", this.myInfo.password);
      formData.append("displayGoods[]", this.goodsSeq);
      formData.append("contents", this.content);
      formData.append("hidden", this.openYn);

      //   for (let pair of formData.entries()) {
      //     console.log(`${pair[0]}: ${pair[1]}`);
      //   }
      this.content = "";
      await this.$store.dispatch("shop/qnaWrite", formData);
      this.$toast.default(this.msg);
      if (this.result) {
        this.cancelWirte();
        this.getQnaList(true);
      }
    },
    deleteQna(seq) {
      if (!seq) return faslse;
      let formData = new FormData();

      formData.append("mode", "board_delete"); //기본값
      formData.append("board_id", "goods_qna"); //기본값
      formData.append("delseq", seq);

      this.$confirm("문의글을 삭제 하시겠습니까?")
        .then(async () => {
          await this.$store.dispatch("shop/deleteQna", {
            formData,
            seq,
          });
          this.$toast.default(this.msg);
        })
        .catch(() => {
          return false;
        });
    },
    modifyQna(seq) {
      const content = document
        .querySelector(`#modify_id_${seq}`)
        .querySelector("textarea").value;
      const hidden = document.querySelector(
        `input[name='open_yn_${seq}']:checked`
      ).value;

      //console.log(content, hidden);

      if (!content || content.replace(/\s/g, "").length <= 0) {
        document
          .querySelector(`#modify_id_${seq}`)
          .querySelector("textarea").value = "";
        this.$toast.default("질문 내용을 작성해 주세요.");
        return false;
      }
      let formData = new FormData();

      formData.append("mode", "board_modify"); //기본값
      formData.append("popup", "1"); //기본값
      formData.append("board_id", "goods_qna"); //기본값
      formData.append("iframe", "1"); //기본값
      formData.append("category", ""); //기본값
      formData.append("subject", "램 상품 문의 입니다."); //기본값
      formData.append("goods_seq", this.goodsSeq);
      formData.append("seq", seq);
      formData.append("name", this.myInfo.user_name);
      formData.append("oldpw", this.myInfo.password);
      formData.append("displayGoods[]", this.goodsSeq);
      formData.append("contents", content);
      formData.append("hidden", hidden);

      // for (let pair of formData.entries()) {
      //   console.log(`${pair[0]}: ${pair[1]}`);
      // }

      this.$confirm("문의글을 수정 하시겠습니까?")
        .then(async () => {
          await this.$store.dispatch("shop/modifyQna", {
            formData,
            seq,
            content,
          });
          this.$toast.default(this.msg);
          this.cancelModify(seq);
        })
        .catch(() => {
          return false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.ques {
  position: relative;
}
.declaration_pop {
  &.qna {
    display: none;
    right: 5px;
    left: auto !important;
    top: 40px !important;
  }
}
.hide {
  display: "none";
}
.none_data {
  text-align: center;
  padding: 20px 0;
  border-top: none !important;
}
</style>
