<template>
  <div class="sect_info_box_3">
    <p v-if="!goodsInfo.common_contents">교환/반품/배송정보가 없습니다.</p>
    <p v-else v-html="goodsInfo.common_contents">
      {{ goodsInfo.common_contents }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    goodsInfo: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>

<style></style>
