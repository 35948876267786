<template>
  <div class="sns_box" style="display: none">
    <h4>공유하기</h4>
    <router-link to="" @click.native="closeSnsPop($event)" class="close_bt"
      ><img src="@/assets/images/sub/close.png" alt=""
    /></router-link>
    <ul>
      <li>
        <router-link to="" @click.native="sendkakao"
          ><img src="@/assets/images/sub/kakao.png" alt=""
        /></router-link>
        <p>카카오톡</p>
      </li>
      <li>
        <router-link to="" @click.native="sendFacebook"
          ><img src="@/assets/images/sub/facebook.png" alt=""
        /></router-link>
        <p>페이스북</p>
      </li>
      <li>
        <router-link to="" @click.native="sendTwitter"
          ><img src="@/assets/images/sub/twiter.png" alt=""
        /></router-link>
        <p>트위터</p>
      </li>
      <!-- <li>
              <a href="#"><img src="@/assets/images/sub/blog.png" alt="" /></a>
              <p>네이버<br />블로그</p>
            </li> -->
      <li>
        <router-link to="" @click.native="sendBand"
          ><img src="@/assets/images/sub/band.png" alt=""
        /></router-link>
        <p>밴드</p>
      </li>
      <li>
        <router-link to="" @click.native="sendkakaoStory"
          ><img src="@/assets/images/sub/story.png" alt=""
        /></router-link>
        <p>카카오<br />스토리</p>
      </li>
      <!-- <li>
              <a href="#"><img src="@/assets/images/sub/cafe.png" alt="" /></a>
              <p>네이버<br />카페</p>
            </li>
            <li>
              <a href="#"><img src="@/assets/images/sub/line.png" alt="" /></a>
              <p>라인</p>
            </li> -->
    </ul>
    <div>
      <input type="text" name="cp_url" readonly v-model="pageUrl" /><router-link
        to=""
        class="url_bt"
        @click.native="cpUrl"
        >URL복사</router-link
      >
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      title: this.item.title,
      description:
        this.item.content.replace(/<[^>]*>?/g, "") || this.item.title,
      imageUrl: `${process.env.VUE_APP_API_URL}${this.item.main_img}`,
      pageUrl: this.snsUrl,
      tmpUrl: this.snsUrl,
    };
  },
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    nowPage: {
      type: String,
      default: "",
    },
  },
  computed: {
    snsUrl() {
      return this.nowPage;
    },
  },
  methods: {
    openPopSns(seq) {
      let snsPop = null;
      if (seq !== null) {
        this.pageUrl = `${this.snsUrl}&seq=${seq}`;
        snsPop = document.querySelector(`#sns_share_${seq}`);
      } else {
        this.pageUrl = this.snsUrl;
        snsPop = document.querySelector("#sns_share");
      }

      const snsClass = document.querySelectorAll(".sns_box");
      [...snsClass].map((sns) => {
        if (sns !== snsPop) {
          sns.style.display = "none";
        }
      });

      if (snsPop.style.display === "none") {
        snsPop.style.display = "block";
      } else {
        snsPop.style.display = "none";
      }
    },
    closeSnsPop(event) {
      event.target.closest("div").style.display = "none";
    },
    sendkakao() {
      console.log(this.title, this.description, this.imageUrl);
      Kakao.Link.sendDefault({
        objectType: "feed",
        content: {
          title: this.title,
          description: this.description,
          imageUrl: this.imageUrl,
          link: {
            mobileWebUrl: this.pageUrl,
            webUrl: this.pageUrl,
          },
        },
        buttons: [
          {
            title: "웹으로 보기",
            link: {
              mobileWebUrl: this.pageUrl,
              webUrl: this.pageUrl,
            },
          },
        ],
      });
    },
    sendkakaoStory() {
      Kakao.Story.share({
        url: this.pageUrl,
        text: `${this.description}`,
      });
    },
    sendBand() {
      let url = encodeURIComponent(this.pageUrl);
      window.open(
        `https://band.us/plugin/share?body=${url}&route=${url}`,
        "_blank"
      );
    },
    sendFacebook() {
      let url = encodeURIComponent(this.pageUrl);
      let title = `${this.description}`;
      window.open(
        `http://www.facebook.com/sharer.php?u=${url}&t=${title}`,
        "_blank"
      );
    },
    sendTwitter() {
      let url = encodeURIComponent(this.pageUrl);
      let title = `${this.description}`;
      window.open(
        `https://twitter.com/intent/tweet?url=${url}&text=${title}`,
        "_blank"
      );
    },
    cpUrl() {
      navigator.clipboard
        .writeText(`${this.pageUrl}`)
        .then(() => {
          this.$toast.default("URL이 복사되었습니다.");
        })
        .catch(() => {
          this.$toast.default("복사 실패!");
        });
    },
  },
};
</script>

<style></style>
