<template>
  <div class="sect_info_box_1">
    <div class="more_info_txt">
      <h4>'직거래 유도 에' 속지 마세요!</h4>
      <p>1. 재고 확인을 위해 먼저 연락해 달라 유인</p>
      <p>2. 할인을 해 주겠다며 별도 계좌로 입금 요구</p>
      <p>3. 안전결제라며 가짜 URL을 통해 결제 유도</p>
      <p>4. 이메일, SNS, 전화 등을 통해 직거래 유도</p>
      <p>
        위 경우 외에도 현금결제를 유도하는 경우 사기의 위험성이 있으니 절대
        결제하지 마시고 위의 [신고하기] 버튼을 눌러 신고해 주세요. 직거래에 의한
        피해에 대해 당사는 일절 책임지지 않습니다.
      </p>
    </div>
    <div v-html="goodsInfo.mobile_contents">
      {{ goodsInfo.mobile_contents }}
    </div>
    <ul class="more_info_list">
      <li>
        판매자 정보<router-link
          :to="`/seller_info/${goodsInfo.provider_seq}?category_id=${categoryId}&goods_seq=${goodsInfo.goods_seq}`"
          >▶</router-link
        >
      </li>
      <li>
        유의할 사항<router-link
          :to="`/caution_info/${goodsInfo.provider_seq}?category_id=${categoryId}&goods_seq=${goodsInfo.goods_seq}`"
          >▶</router-link
        >
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    categoryId: {
      type: String,
      default: "",
    },
    goodsInfo: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>

<style></style>
