<template>
  <div class="rn_my_wrap">
    <GoodsSearch />
    <!---->
    <!-- <div class="rn_my_empty_30"></div> -->
    <!---->
    <MainMenu
      :mainMenuList="mainMenuList"
      @getMenu="getMenu"
      :isGoods="true"
      :allowMenu="allowMenu"
    />
    <!---->
    <div class="rn_ms_section goods_border">
      <!---->
      <!-- <div class="cate_wrap">
        <div class="cate_left">
          <router-link to="/market_category_list"
            ><img src="@/assets/images/sub/cate_menu_ico.png" alt=""
          /></router-link>
        </div> -->
      <!-- <div class="cate_right">
   
          <GoodsCategory
            v-if="fisrtId"
            :category="categories"
            :id="fisrtId"
            ref="goodsCategory"
            @getGoodsCategory="getGoodsCategory"
          />
    
        </div> -->
      <!-- </div> -->
      <!---->
      <div class="rn_ms_box_5" v-if="assign.provider">
        <div class="sect_market_img" @click="moveMiniShop">
          <img
            :src="
              assign.provider.banner_visual
                ? assign.provider.banner_visual
                : '/data/skin/interior_comfortable_gls/images/common/mns_banner.gif'
            "
            alt=""
          />
        </div>
        <div class="sect_location">
          <span @click="goMainList()">상품 >&nbsp;</span>
          <template v-for="(name, index) in categoryName">
            <span
              to=""
              :key="index"
              :id="`${categoryName.length}_${index}`"
              :data-level="name.level"
              :data-id="name.id"
              :class="{ shape_yellow: parseInt(name.level) === 5 }"
              @click="goCategory($event)"
              >{{ name.title }} >
            </span>
          </template>
          <div class="location_title">&nbsp;{{ goodsInfo.goods_name }}</div>
        </div>
        <div class="sect_detail">
          <div class="menubar type_1" v-if="myInfo.channel === 'reviewer'">
            <router-link :to="reviewUrl" class="white left"
              >리뷰<br />보기</router-link
            >
            <router-link to="" @click.native="declaration" class="white left"
              >신고<br />하기</router-link
            >
            <router-link to="" class="black right"
              >보상비<br />1.0%</router-link
            >
            <router-link
              to=""
              class="yellow right"
              @click.native="openProReview"
              v-if="parseInt(proReviewCnt) <= 0"
              >전문가<br />리뷰하기</router-link
            >
            <router-link
              to=""
              class="orange double"
              @click.native="openProReview"
              v-if="parseInt(proReviewCnt) > 0"
              ><div class="txt">전문가 리뷰<br />바구니</div>
              <div class="num">{{ proReviewCnt }}</div></router-link
            >
            <router-link
              :to="`/review/review_normal_goods_choice/${id}/${goodsSeq}`"
              class="yellow right"
              >소비자<br />리뷰하기</router-link
            >
            <div class="basket_pop" v-if="showProReview">
              <h4>전문가 리뷰하기 바구니</h4>
              <div class="info">
                <p>
                  리뷰할 상품이 담겼습니다. 전문가 리뷰는 모두 비교 리뷰입니다.
                </p>
                <p>비교할 상품은 3개까지 선택 가능합니다.</p>
                <p>
                  비교할 상품을 찾아 [전문가 리뷰 바구니] 버튼을 눌러주세요.
                </p>
              </div>
              <div class="cont">
                <div
                  class="box"
                  v-for="(goods, index) in proReviewList"
                  :key="index"
                >
                  <div>
                    {{
                      index <= 0
                        ? "메인 A"
                        : index > 0 && index < 2
                        ? "비교 B"
                        : index >= 2
                        ? "비교 C"
                        : ""
                    }}
                  </div>
                  <div>
                    <p>
                      {{ goods.goodsName }}
                    </p>
                  </div>
                  <div>
                    <router-link to="" @click.native="deleteReviewBasket(index)"
                      >X</router-link
                    >
                  </div>
                </div>
              </div>
              <div class="btn">
                <router-link
                  to=""
                  class="gray"
                  @click.native="showProReview = false"
                  >계속</router-link
                >
                <router-link
                  to=""
                  class="black"
                  @click.native="goProReviewWrite"
                  >완료</router-link
                >
              </div>
            </div>
          </div>

          <div
            class="menubar"
            v-if="myInfo.channel !== 'reviewer' && myInfo.channel !== 'seller'"
          >
            <router-link :to="reviewUrl" class="white left"
              >리뷰<br />보기</router-link
            >
            <router-link to="" @click.native="declaration" class="white left"
              >신고<br />하기</router-link
            >
            <router-link to="" class="black right" @click.native="openPopSns"
              >공유<br />하기</router-link
            >
            <span class="gray right">전문가<br />리뷰하기</span>
            <span class="gray right">소비자<br />리뷰하기</span>
          </div>
          <div
            class="menubar"
            v-if="
              myInfo.channel === 'seller' &&
              parseInt(goodsInfo.provider_seq) === parseInt(myInfo.provider_seq)
            "
          >
            <router-link :to="reviewUrl" class="white left"
              >리뷰<br />보기</router-link
            >
            <router-link to="" class="black right" @click.native="openPopSns"
              >공유<br />하기</router-link
            >
            <router-link
              :to="`/review/review_seller_goods_choice/${id}/${goodsSeq}`"
              class="yellow right"
              >판매자<br />리뷰하기</router-link
            >
          </div>
          <div
            class="menubar"
            v-if="
              myInfo.channel === 'seller' &&
              parseInt(goodsInfo.provider_seq) !== parseInt(myInfo.provider_seq)
            "
          >
            <router-link :to="reviewUrl" class="white left"
              >리뷰<br />보기</router-link
            >
            <router-link to="" class="black right" @click.native="openPopSns"
              >공유<br />하기</router-link
            >
            <router-link to="" class="gray right"
              >판매자<br />리뷰하기</router-link
            >
          </div>

          <div class="declaration_pop" v-if="isDeclaration">
            <!--신고하기팝업-->
            <h4>아래에서 신고할 내용을 선택해주세요.</h4>
            <div class="sel_type">
              <ul>
                <li>
                  <label
                    ><input
                      type="radio"
                      name="declaration"
                      value="0"
                      v-model="inputData"
                    />상품명이 잘못되었습니다.</label
                  >
                </li>
                <li>
                  <label
                    ><input
                      type="radio"
                      name="declaration"
                      value="1"
                      v-model="inputData"
                    />카테고리 경로가 잘못되었습니다.</label
                  >
                </li>
                <li>
                  <label
                    ><input
                      type="radio"
                      name="declaration"
                      value="2"
                      v-model="inputData"
                    />불법물, 음란물이 포함되어 있습니다.</label
                  >
                </li>
                <li>
                  <label
                    ><input
                      type="radio"
                      name="declaration"
                      value="3"
                      v-model="inputData"
                    />직거래를 유도하는 내용입니다.</label
                  >
                </li>
                <li>
                  <label
                    ><input
                      type="radio"
                      name="declaration"
                      value="4"
                      v-model="inputData"
                    />불법 유통되는 상품입니다.</label
                  >
                </li>
                <li>
                  <label
                    ><input
                      type="radio"
                      name="declaration"
                      value="etc"
                      v-model="inputData"
                    />기타(아래에 내용을 적어주세요)</label
                  >
                </li>
              </ul>
              <textarea v-model="etcContent" :readonly="isRedonly"></textarea>
            </div>
            <div class="btn">
              <router-link to="" class="black" @click.native="reportSet"
                >신고</router-link
              ><router-link to="" class="gray" @click.native="declaration"
                >취소</router-link
              >
            </div>
          </div>
          <ReviewSnsShare
            v-if="goods.title"
            :item="goods"
            :nowPage="nowPage"
            :ref="`sns_share`"
            :id="`sns_share`"
          />
          <GoodsImg :images="images" />
          <div class="info_box">
            <div class="sect_info_1">
              <div class="box">
                <h3>{{ goodsInfo.goods_name }}</h3>
                <h4 v-if="parseInt(goodsInfo.sale_rate) > 0">
                  {{ goodsInfo.sale_rate }}%<span
                    >{{ won(parseInt(goodsInfo.org_price)) }}원</span
                  >
                </h4>
                <h5>{{ won(goodsInfo.sale_price) }}원</h5>
              </div>
              <div class="box">
                <p>
                  5만원 이상 무이자 |
                  <span @click="popup">카드 자세히 보기</span>
                </p>
                <p v-for="(shipping, index) in shippingSet" :key="`${index}_1`">
                  {{ shipping.shipping_set_name }} |
                  <b> {{ shippingAddMsgStr(shipping.delivery_std_input) }}원</b>
                  <span v-if="overShippingCost > 0"
                    >({{ `${won(overShippingCost)}원 이상 무료` }})</span
                  >
                  <span>({{ prePayTxt(shipping.prepay_txt) }})</span>
                </p>
                <p v-for="(shipping, index) in shippingSet" :key="`${index}_2`">
                  <span v-if="shipping.delivery_add_input"
                    >추가 배송비 |<b>
                      {{ shippingAddMsg(shipping.delivery_add_input) }}</b
                    ></span
                  >
                </p>
                <!-- <p>예상 배송일 | 2일 이내</p> -->
              </div>
            </div>
            <div
              class="sect_info_2"
              v-for="(option, index) in optionData"
              :key="index"
            >
              <div class="tit">상품 옵션 ({{ option.title }})</div>
              <select
                name="default_option"
                @change="addOption($event)"
                @focus="tempName($event)"
                :data-idx="index"
                :data-title="option.title"
              >
                <option value="">선택</option>
                <option
                  v-for="(item, idx) in option.options"
                  :key="`opt_${idx}`"
                  :value="item.opt"
                  :v-model="defaultOption"
                  v-html="item.opt_string"
                >
                  {{
                    item.chk_stock
                      ? item.opt_string
                      : `${item.opt_string}[품절]`
                  }}
                </option>
              </select>
            </div>
            <div
              class="sect_info_2"
              v-for="(sub, sidx) in subOptions"
              :key="`sub_${sidx}`"
            >
              <div class="tit">추가 상품 ({{ sub[0].suboption_title }})</div>
              <select
                name="subOption"
                @change="addOption($event)"
                @focus="tempName($event)"
                :data-title="sub[0].suboption_title"
                :data-required="sub[0].sub_required.toLowerCase()"
              >
                <option value="">
                  {{
                    sub[0].sub_required.toLowerCase() === "n"
                      ? "선택"
                      : "필수선택"
                  }}
                </option>
                <option
                  v-for="(sop, oidx) in sub"
                  :value="sop.suboption"
                  :key="`sub_op_${oidx}`"
                >
                  {{ sop.suboption
                  }}{{
                    sop.price
                      ? `&nbsp;(추가 ${won(parseInt(sop.price))}&#x20a9;)`
                      : ""
                  }}
                  {{ !sop.chk_stock ? "[품절]" : "" }}
                </option>
              </select>
            </div>
            <OptionItem
              v-if="orderData.goods"
              :orderData="orderData"
              :isShop="true"
              @setTotal="setTotal"
              ref="optionItem"
            />
            <ul class="sect_info_4">
              <li>상품금액 : {{ goodsCost }}원</li>
              <li>
                배송비 :
                {{
                  parseInt(overShippingCost) > 0 &&
                  parseInt(diffGoodsCost) >= parseInt(overShippingCost)
                    ? "무료"
                    : shippingCostTot
                }}
              </li>
              <li class="common">
                결제할 금액 : <b>총 {{ won(totalCost) }}원</b>
              </li>
            </ul>
            <div
              class="sect_info_5"
              :style="info ? ' padding-bottom: 15px;' : ' padding-bottom: 0px'"
            >
              <div class="tit">
                상품정보<router-link to="" @click.native="showInfo"
                  >▼</router-link
                >
              </div>
              <table summary="" v-if="info">
                <tbody>
                  <tr>
                    <th>제품명</th>
                    <td>{{ goodsInfo.goods_name }}</td>
                  </tr>
                  <template v-if="goodsInfo.sub_info_desc">
                    <tr
                      v-for="(goods, index) in goodsInfo.sub_info_desc.subInfo"
                      :key="index"
                    >
                      <th>{{ goods.title }}</th>
                      <td>{{ goods.desc }}</td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
            <ul class="sect_info_tab">
              <li class="t_1 on">
                <router-link
                  to=""
                  @click.native="openTab('goodsDetail', $event)"
                  >상세정보</router-link
                >
              </li>
              <li class="t_2">
                <router-link to="" @click.native="openTab('goodsQna', $event)"
                  >Q&A</router-link
                >
              </li>
              <li class="t_3">
                <router-link
                  to=""
                  @click.native="openTab('goodsRefund', $event)"
                  >반품/교환안내</router-link
                >
              </li>
            </ul>
          </div>
          <MarketGoodsInfo
            :goodsInfo="goodsInfo"
            :categoryId="id"
            v-if="goodsDetail"
          />
          <MarketGoodsQna :goodsSeq="goodsSeq" v-if="goodsQna" />
          <MarketGoodsRefund :goodsInfo="goodsInfo" v-if="goodsRefund" />
        </div>
        <div class="button_box" id="button_box">
          <router-link
            to=""
            class="yellow"
            @click.native="purchase"
            v-if="goodsInfo.goods_status === 'normal'"
            >구매하기</router-link
          >
          <router-link
            to=""
            class="sold_out"
            v-if="goodsInfo.goods_status === 'runout'"
            >품절</router-link
          >
          <router-link
            to=""
            class="sold_out"
            v-if="goodsInfo.goods_status === 'unsold'"
            >판매중지</router-link
          >
          <router-link
            to=""
            class="sold_out"
            v-if="goodsInfo.goods_status === 'purchasing'"
            >재고확보중</router-link
          >
          <router-link to="" class="white" @click.native="setAdd"
            >장바구니</router-link
          >
        </div>
        <div class="move_button_box" v-if="buttonShow">
          <router-link
            to=""
            class="yellow"
            @click.native="purchase"
            v-if="goodsInfo.goods_status === 'normal'"
            >구매하기</router-link
          >
          <router-link
            to=""
            class="sold_out"
            v-if="goodsInfo.goods_status === 'runout'"
            >품절</router-link
          >
          <router-link
            to=""
            class="sold_out"
            v-if="goodsInfo.goods_status === 'unsold'"
            >판매중지</router-link
          >
          <router-link
            to=""
            class="sold_out"
            v-if="goodsInfo.goods_status === 'purchasing'"
            >재고확보중</router-link
          >
          <router-link to="" class="white" @click.native="setAdd"
            >장바구니</router-link
          >
        </div>
        <div class="rn_info">
          주식회사 리뷰앤메이크머니는 통신판매 당사자가 아닌
          통신판매중개자입니다. 상품, 상품정보, 거래에 관한 의무와 책임은
          통신판매 당사자인 판매자에게 있습니다.
        </div>
      </div>
      <!---->
    </div>
    <!---->
  </div>
</template>

<script>
import { mapState } from "vuex";
import MainMenu from "@/components/shop/menu/MainMenu";
// import GoodsCategory from "@/components/shop/menu/GoodsCategory";
import GoodsImg from "@/components/shop/goods/GoodsImg";
import OptionItem from "@/components/mypage/mycart/OptionItem";
import MarketGoodsInfo from "@/components/shop/menu/MarketGoodsInfo";
import MarketGoodsQna from "@/components/shop/menu/MarketGoodsQna";
import MarketGoodsRefund from "@/components/shop/menu/MarketGoodsRefund";
import ReviewSnsShare from "@/components/review/state/ReviewSnsShare";
import GoodsSearch from "@/components/shop/menu/GoodsSearch.vue";
export default {
  data() {
    return {
      defaultOption: "",
      first: true,
      tmpName: "",
      addData: {},
      totalCost: 0,
      shippingCost: 0,
      overShippingCost: 0,
      goodsCost: 0,
      diffGoodsCost: 0,
      goodsSeq: this.$route.params.goodsSeq || null,
      id: this.$route.params.categoryId || null,
      qna: this.$route.params.qna || null,
      orderTemp: {
        sessionMember: {},
        goods: {},
      },
      etcContent: null,
      goodsDetail: true,
      goodsQna: false,
      goodsRefund: false,
      isDeclaration: false,
      isRedonly: false,
      reviewSeq: this.$route.query.review_seq || null,
      allowMenu: [1], //현재 램 장터메 활성화된 메뉴 번호
      showProReview: false,
      proReviewList: [],
      searchText: "",
      info: false,
      buttonShow: true,
    };
  },
  components: {
    MainMenu,
    // GoodsCategory,
    GoodsImg,
    OptionItem,
    MarketGoodsInfo,
    MarketGoodsQna,
    MarketGoodsRefund,
    ReviewSnsShare,
    GoodsSearch,
  },
  created() {
    //초기화
    this.$store.dispatch("shop/dataClear");
    this.getMainMenu();

    this.$store.dispatch("common/setFooter", {
      viewFooter: true,
    });
    this.checkBasket();
  },
  mounted() {
    window.addEventListener("scroll", this.scrollEvents);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.scrollEvents);
  },
  computed: {
    ...mapState("review", ["mainMenuList"]),
    ...mapState("mypage", ["myInfo"]),
    ...mapState("order", { orderResult: "result", orderMsg: "msg" }),
    ...mapState("shop", [
      "goodsInfo",
      "assign",
      "optionData",
      "subOptions",
      "result",
      "msg",
      "categories",
      "listArray",
      "categoryName",
      "basket",
    ]),
    inputData: {
      get() {
        return "etc";
      },
      set(value) {
        if (value !== "etc") {
          this.isRedonly = true;
          this.etcContent = null;
        } else {
          this.isRedonly = false;
        }
      },
    },
    images() {
      return this.assign.images;
    },
    shippingSet() {
      let list = [];
      if (this.assign.shipping_set) {
        Object.keys(this.assign.shipping_set).forEach((key) => {
          list.push(this.assign.shipping_set[key]);
        });
      }

      return list;
    },
    isRequried() {
      if (this.subOptions) {
        for (let value of this.subOptions) {
          if (value[0].sub_required.toLowerCase() === "y") {
            return true;
          }
        }
      }
      return false;
    },
    orderData() {
      this.orderTemp.sessionMember.member_seq = this.goodsInfo.member_seq;
      this.orderTemp.sessionMember.member_seq = this.goodsInfo.group_seq;
      this.orderTemp.config_system = this.goodsInfo.config_system;
      this.orderTemp.goods = this.goodsInfo;
      this.orderTemp.options = this.optionData || [];
      this.orderTemp.cart_options = this.basket;
      this.orderTemp.suboptions = this.subOptions;
      return this.orderTemp;
    },
    shippingCostTot() {
      if (
        this.overShippingCost > 0 &&
        this.overShippingCost <= this.totalCost
      ) {
        this.shippingCost = 0;
        return 0;
      } else {
        return `${this.$won(this.shippingCost)}원`;
      }
    },
    fisrtId() {
      if (this.categoryName.length > 0) {
        return this.categoryName[0].id;
      }
      return false;
    },
    proReviewCnt() {
      return this.proReviewList.length;
    },
    nowPage() {
      return `${process.env.VUE_APP_API_URL}${this.$route.fullPath}`;
    },
    goods() {
      const data = {
        title: this.goodsInfo.goods_name,
        content: this.goodsInfo.summary,
        main_img: `/${this.assign.APP_IMG}`,
      };
      return data;
    },
    reviewUrl() {
      if (this.categoryName.length > 0) {
        const url = `/review/goods_main/${
          this.categoryName[this.categoryName.length - 1].category_code
        }?goods_seq=${
          this.goodsInfo.goods_seq
        }&option1=1&option2=0&option3=0&seq=0`;
        return url;
      }
      return "";
    },
  },
  methods: {
    popup() {
      window.open("https://pgweb.tosspayments.com/tosspayments/MainPopUp.do");
    },
    scrollEvents() {
      if (!document.querySelector("#button_box")) {
        return false;
      }

      let scrollbar = document.querySelector("html").scrollTop;
      let button = document.querySelector("#button_box").offsetTop;

      // const cha = button - scrollbar;
      // console.log(button, scrollbar, cha);
      if (button < scrollbar + 700) {
        this.buttonShow = false;
      } else {
        this.buttonShow = true;
      }
    },
    showInfo(e) {
      this.info = !this.info;
      if (this.info) {
        e.target.textContent = "▲";
      } else {
        e.target.textContent = "▼";
      }
    },
    goSearch() {
      if (this.searchText.replace(/\s/g, "").length <= 0) {
        this.$toast.default("검색어를 입력하세요.");
        return false;
      }
      this.$router.push(`/search/goods_search?search=${this.searchText}`);
    },
    needsLogin() {
      if (!this.$store.state.member.isLogin) {
        this.$confirm("로그인이 필요합니다.")
          .then(() => {
            this.$store.dispatch("common/setReferer", {
              path: this.$route.path,
            });
            this.$router.push("/member/login");
            return false;
          })
          .catch(() => {
            return false;
          });

        return false;
      }
      return true;
    },
    moveMiniShop() {
      this.$router.push(`/mini_shop/${this.goodsInfo.provider_seq}`);
    },
    getReviewGoods() {
      let proReview = null;
      try {
        proReview = JSON.parse(sessionStorage.getItem("reviewGoods"));
      } catch (error) {
        console.log(error);
      }
      if (proReview) {
        return proReview;
      }
      return false;
    },
    setReviewGoods() {
      sessionStorage.removeItem("reviewGoods");
      sessionStorage.setItem("reviewGoods", JSON.stringify(this.proReviewList));
    },
    checkBasket() {
      const proReview = this.getReviewGoods();
      if (proReview) {
        this.proReviewList = proReview;
      }
    },
    openProReview() {
      this.showProReview = !this.showProReview;
      if (!this.showProReview) {
        return false;
      }
      const proReview = this.getReviewGoods();

      if (proReview) {
        this.proReviewList = proReview;
        if (proReview.length < 3) {
          const check = this.proReviewList.findIndex(
            (goods) =>
              parseInt(goods.goodsSeq) === parseInt(this.goodsInfo.goods_seq)
          );
          console.log(proReview, check);
          if (check === -1) {
            this.proReviewList.push({
              goodsName: this.goodsInfo.goods_name,
              goodsSeq: this.goodsInfo.goods_seq,
            });
          }
        }
      } else {
        this.proReviewList.push({
          goodsName: this.goodsInfo.goods_name,
          goodsSeq: this.goodsInfo.goods_seq,
        });
      }
      this.setReviewGoods();
    },
    deleteReviewBasket(index) {
      const proReview = this.getReviewGoods();

      if (proReview) {
        if (proReview.length < 2) {
          sessionStorage.removeItem("reviewGoods");
          this.proReviewList = [];
          this.showProReview = false;
        } else {
          proReview.splice(index, 1);
          this.proReviewList = proReview;
          this.setReviewGoods();
        }
      } else {
        this.$toast.default("전문가 리뷰 바구니에 리뷰할 상품이 없습니다.");
      }
    },
    goProReviewWrite() {
      const proReview = this.getReviewGoods();
      if (proReview) {
        if (proReview.length < 2) {
          this.$toast.default(
            "전문가 리뷰는 비교 상품 2개 이상부터 작성할 수 있습니다. "
          );
          return false;
        } else {
          this.$router.push(
            `/review/review_pro_goods_choice?category=${this.id}&goods_seq=${this.goodsInfo.goods_seq}`
          );
        }
      }
    },
    declaration() {
      if (!this.needsLogin()) return false;
      this.isDeclaration = !this.isDeclaration;
      this.etcContent = null;
    },
    openTab(name, event) {
      const ulEl = [...event.target.closest("ul").querySelectorAll("li")];

      ulEl.map((li) => {
        li.classList.remove("on");
      });
      event.target.closest("li").classList.add("on");

      this.goodsDetail = false;
      this.goodsQna = false;
      this.goodsRefund = false;
      this.$data[name] = true;
    },
    openQna() {
      const ulEl = [
        ...document.querySelector(".sect_info_tab").querySelectorAll("li"),
      ];

      ulEl.map((li) => {
        li.classList.remove("on");
      });
      ulEl[1].classList.add("on");
      this.goodsDetail = false;
      this.goodsRefund = false;
      this.goodsQna = true;
    },
    openPopSns() {
      this.$refs[`sns_share`].openPopSns(null);
    },
    prePayTxt(str) {
      return str.replace(/(<([^>]+)>)/gi, "");
    },
    shippingAddMsgStr(str) {
      let tmp = str.replace(/(&#x20a9;)|(<([^>]+)>)|(,)/g, "").split("(");
      if (tmp.length >= 2) {
        this.shippingCost = parseInt(tmp[0]);
        tmp = tmp[1].split(" ");
        this.overShippingCost = parseInt(tmp[0]);
      } else {
        if (isNaN(tmp[0])) {
          this.shippingCost = 0;
        } else {
          this.shippingCost = parseInt(tmp[0]);
        }
      }
      return this.$won(this.shippingCost);
    },
    shippingAddMsg(str) {
      return str.replace(/(<.*?\/.*>)|(\s)|(&#x[0-9a-z]+;)/g, "");
    },
    goMainList() {
      this.$router.push("/market_main_list").catch(() => {});
    },
    won(str) {
      return this.$won(str);
    },
    getDataSearch() {
      //전혀 다른 검색화면
    },
    getGoodsCategory(id) {
      this.$router.push(`/market_main_list/${id}`);
    },
    goCategory(event) {
      const id = event.target.dataset.id;
      const level = event.target.dataset.level;
      if (parseInt(level) <= 1) {
        this.$router.push(`/market_main_list/${id}`);
      } else if (parseInt(level) > 1 && parseInt(level) < 5) {
        this.$router.push(`/market_sub_list/${level}/${id}`);
      } else {
        this.$router.push(
          `/market_goods_list/5/${
            this.categoryName[this.categoryName.length - 1].id
          }`
        );
      }
    },
    async reportSet() {
      const value = document.querySelector(
        "input[name='declaration']:checked"
      ).value;
      if (value === "etc") {
        if (
          !this.etcContent ||
          this.etcContent.replace(/\s/g, "").length <= 0
        ) {
          this.etcContent = null;
          this.$toast.default(
            "기타 신고할 내용을 입력하시거나, 신고할 내용을 선택해주세요."
          );
          return false;
        }
      }
      await this.$store.dispatch("mypage/reportSet", {
        report_type: "goods",
        report_seq: this.goodsSeq,
        report_reaseon: value,
        content: this.etcContent,
      });
      this.etcContent = null;
      this.$toast.default(this.$store.state.mypage.msg);
      this.isDeclaration = false;
    },
    async getMainMenu() {
      await this.$store.dispatch("review/getMainMenu");
      this.getGoodsDetail(true);
    },
    async getGoodsDetail(catetory = false) {
      await this.$store.dispatch("shop/getGoodsDetail", {
        goods_seq: this.goodsSeq,
        id: this.id,
        search_text: this.keyword,
      });
      if (!this.result) {
        this.$toast.default(this.msg);
        this.$router.go(-1);
      }
      if (this.qna === "qna") {
        this.openQna();
        const qna = document.querySelector(".sect_info_tab");
        qna.scrollIntoView({ behavior: "smooth" });
      }
      if (!this.optionData) {
        this.$store.dispatch("shop/updateOptions", {
          type: "add",
          data: {
            title1: null,
            title2: null,
            title3: null,
            title4: null,
            title5: null,
            option1: null,
            option2: null,
            option3: null,
            option4: null,
            option5: null,
            ea: "1",
            consumer_price: this.goodsInfo.consumer_price,
            price: parseInt(this.goodsInfo.sale_price),
            cart_suboptions: [],
            opt: "",
          },
        });
        // OrderItem은.. 기존 오브젝트에 값이 생성된 상태에서 값만 변경된 것은, 강제로 리렌더 시켜야 함.
        this.$refs.optionItem.reRender();
      }
      // if (catetory) {
      //   this.$refs.goodsCategory.reflash();
      // }
    },
    tempName(event) {
      this.tmpName = event.target.value;
    },
    async addOption(event) {
      let defaultOption = "";
      let subOption = "";
      if (event.target.name === "default_option") {
        //필수 옵션 들이 다 들어가 있는지 확인
        // if (this.isRequried) {
        //   if (!this.requriedItemCheck(event)) {
        //     return false;
        //   }
        // }
        if (this.goodsInfo.option_view_type.toLowerCase() !== "join") {
          this.moreOption(event.target.dataset.idx);
          return false;
        }
        defaultOption = event.target.value;

        //메인 옵션이 바뀌면 추가 옵션 초기화
        [...document.querySelectorAll("select[name='subOption'")].map(
          (sel) => (sel.value = "")
        );

        //매인 옵션 일 경우
        if (!defaultOption) {
          //매인 옵션 인 데 선댁 값이 없으면 초기화
          this.addData = {};
          return false;
        }
        //임시 배열
        let data = [];
        let titleArray = [];
        this.optionData.map((option) => {
          // 맵 돌면서 필터로 같은 옵션 찾아서 임시 배열에 추가
          (data = option.options.filter((op) => op.opt === defaultOption)),
            (titleArray = option.title.split(","));
        });

        // 본 옵션 데이터 추가
        this.addData.title1 = titleArray[0] || "";
        this.addData.title2 = titleArray[1] || "";
        this.addData.title3 = titleArray[2] || "";
        this.addData.title4 = titleArray[3] || "";
        this.addData.title5 = titleArray[4] || "";
        this.addData.option1 = data[0].option1;
        this.addData.option2 = data[0].option2;
        this.addData.option3 = data[0].option3;
        this.addData.option4 = data[0].option4;
        this.addData.option5 = data[0].option5;
        this.addData.ea = "1";
        this.addData.consumer_price = data[0].consumer_price;
        this.addData.price = parseInt(data[0].price);
        this.addData.cart_suboptions = [];
        this.addData.opt = data[0].opt;

        // 추가 옵션이 필수 라면 추가 상품을 선택 해주세요
        if (this.isRequried) {
          // 동일 옵션 존재 여부 확인
          try {
            await this.dupleCheck();
            this.$toast.default("필수 추가 상품을 선택 해주세요.");
          } catch (e) {
            this.$toast.default("동일한 옵션이 존재 합니다.");
            this.tmpOptData = this.addData.opt;

            //선택 옵션 데이터 초기화
            this.addData = {};
          }
        } else {
          // 추가 상품이 필수 가 아니면 basket  에 추가
          try {
            await this.dupleCheck();
            this.$store.dispatch("shop/updateOptions", {
              type: "add",
              data: this.addData,
            });
          } catch (e) {
            this.$toast.default("동일한 옵션이 존재 합니다.");
          }

          this.tmpOptData = this.addData.opt;

          //선택 옵션 데이터 초기화
          this.addData = {};

          this.$refs.optionItem.reRender();
        }
      } else {
        //추가 옵션 일 겨우
        subOption = event.target.value;

        if (!subOption) {
          return false;
        }
        // 동일 옵션 존재 여부 확인
        try {
          await this.dupleCheck();
        } catch (e) {
          this.$toast.default("동일한 옵션이 존재 합니다.");
          return false;
        }
        //본 온션 선택 데이터가 있는 경우
        if (!this.addData.cart_suboptions) {
          //basket 에 카트 옵션 데이터가 있다면
          if (this.basket.length > 0) {
            //임시 배열
            let data = [];

            // 맵 돌면서 필터로 같은 옵션 찾아서 임시 배열에 추가
            this.subOptions.map((sub) => {
              data = [...data, ...sub.filter((s) => s.suboption === subOption)];
            });
            //추가 옵션 검사
            let isRequriedOk = false;
            if (this.isRequried) {
              //필수 옵션 이 있는 상품 이라면
              for (let v of data) {
                //찾아온 데이터에서 검사
                if (v.sub_required.toLowerCase() !== "y") {
                  //선택한 추가 옵션이 필수 가 아니라면
                  for (let sub of this.subOptions) {
                    //추가 옵션을 돌면서...
                    for (let subSub of sub) {
                      if (subSub.sub_required.toLowerCase() === "y") {
                        //필수 추가 옵션 항목이 맞다면
                        for (let subCart of this.basket[this.basket.length - 1]
                          .cart_suboptions) {
                          //장마구니에 마지막 배열을 돌면서 필수 추가 상품이 있는지 검사
                          if (
                            subCart.suboption_title ===
                              subSub.suboption_title &&
                            subCart.suboption === subSub.suboption
                          ) {
                            isRequriedOk = true;
                            break;
                          }
                        }
                      }
                      if (isRequriedOk) {
                        break;
                      }
                    }
                  }
                  if (!isRequriedOk) {
                    //선택한 추가 옵션과 카트에 옵션 중에 필수 추가 옵션이 없으면 ...
                    this.$toast.default("필수 추가 상품을 선택 해주세요.");
                    event.target.value = "";
                    return false;
                  }
                }
              }
            }

            //동일한 옵션에  동일한 추가 상품 검사
            let tmp;
            let check = true;
            let index = 0;
            this.basket.forEach((item, idx) => {
              if (item.option1) {
                tmp = item.option1;
              }
              if (item.option2) {
                tmp = `${item.option1}|${item.option2}`;
              }
              if (item.option3) {
                tmp = `${item.option1}|${item.option2}|${item.option3}`;
              }
              if (item.option4) {
                tmp = `${item.option1}|${item.option2}|${item.option3}|${item.option4}`;
              }
              if (item.option5) {
                tmp = `${item.option1}|${item.option2}|${item.option3}|${item.option4}|${item.option5}`;
              }

              if (tmp === this.tmpOptData) {
                index = idx;
                this.basket[idx].cart_suboptions.forEach((sub) => {
                  data.forEach((dt) => {
                    if (
                      dt.suboption_title === sub.suboption_title &&
                      dt.suboption === sub.suboption
                    ) {
                      check = false;
                    }
                  });
                });
              }
            });
            if (!check) {
              this.$toast.default(
                "동일한 옵션에 동일한 추가 상품이 존재 합니다."
              );
              return false;
            }

            //basket 에 상품 데이터 추가
            data.map((dt) => {
              this.$store.dispatch("shop/updateSubOptions", {
                idx: index,
                type: "add",
                data: {
                  suboption_title: dt.suboption_title,
                  suboption: dt.suboption,
                  ea: "1",
                  price: dt.price,
                  consumer_price: dt.consumer_price,
                },
              });
            });
            this.$refs.optionItem.reRender();
            //선택 옵션 데이터 초기화
            this.addData = {};
          } else {
            //basket에  상품이 없다면 본 상품을 선택 해주세요
            this.$toast.default("상품 옵션을 선택 해주세요.");
            subOption = "";
            return false;
          }
        } else {
          //선택한 메인 옵션이 있을경우

          //임시 배열
          let data = [];

          // 맵 돌면서 필터로 같은 추가 옵션 찾아서 임시 배열에 추가
          this.subOptions.map((sub) => {
            data = [...data, ...sub.filter((s) => s.suboption === subOption)];
          });

          if (this.isRequried) {
            for (let v of data) {
              if (v.sub_required.toLowerCase() !== "y") {
                this.$toast.default("필수 추가 상품을 선택 해주세요.");
                event.target.value = "";
                return false;
              }
            }
          }
          //상품 배열에 추가 상품 추가
          data.map((dt) => {
            this.addData.cart_suboptions.push({
              suboption_title: dt.suboption_title,
              suboption: dt.suboption,
              ea: "1",
              price: dt.price,
              consumer_price: dt.consumer_price,
            });
          });

          //basket 에 상품 데이터 추가
          this.$store.dispatch("shop/updateOptions", {
            type: "add",
            data: this.addData,
          });

          this.tmpOptData = this.addData.opt;

          //선택 옵션 데이터 초기화
          this.addData = {};

          this.$refs.optionItem.reRender();
        }
      }
    },
    async moreOption(index) {
      //메인 옵션이 바뀌면 추가 옵션 초기화
      [...document.querySelectorAll("select[name='subOption'")].map(
        (sel) => (sel.value = "")
      );

      let query = `no=${this.goodsInfo.goods_seq}&member_seq=${this.goodsInfo.member_seq}&max=${this.goodsInfo.option_depth}`;
      let idx = parseInt(index) + 1;
      let array = [
        ...document.querySelectorAll("select[name='default_option'"),
      ];
      for (let i = 0; i < idx; i++) {
        query += `&options[]=${array[i].value}`;
      }
      if (idx < parseInt(this.goodsInfo.option_depth)) {
        document.querySelectorAll("select[name='default_option'")[idx].value =
          "";
        await this.$store.dispatch("shop/moreOption", {
          query,
          idx,
          max: parseInt(this.goodsInfo.option_depth),
          type: "shop",
        });
      }
      if (idx === parseInt(this.goodsInfo.option_depth)) {
        let seletedIdx = document.querySelectorAll(
          "select[name='default_option'"
        )[idx - 1].selectedIndex;

        let data = this.optionData[idx - 1].options[seletedIdx - 1];

        // 본 옵션 데이터 추가
        this.addData.title1 = document.querySelectorAll(
          "select[name='default_option'"
        )[0]
          ? document.querySelectorAll("select[name='default_option'")[0].dataset
              .title
          : "";
        this.addData.title2 = document.querySelectorAll(
          "select[name='default_option'"
        )[1]
          ? document.querySelectorAll("select[name='default_option'")[1].dataset
              .title
          : "";
        this.addData.title3 = document.querySelectorAll(
          "select[name='default_option'"
        )[2]
          ? document.querySelectorAll("select[name='default_option'")[2].dataset
              .title
          : "";
        this.addData.title4 = document.querySelectorAll(
          "select[name='default_option'"
        )[3]
          ? document.querySelectorAll("select[name='default_option'")[3].dataset
              .title
          : "";
        this.addData.title5 = document.querySelectorAll(
          "select[name='default_option'"
        )[4]
          ? document.querySelectorAll("select[name='default_option'")[4].dataset
              .title
          : "";
        this.addData.option1 = document.querySelectorAll(
          "select[name='default_option'"
        )[0]
          ? document.querySelectorAll("select[name='default_option'")[0].value
          : "";
        this.addData.option2 = document.querySelectorAll(
          "select[name='default_option'"
        )[1]
          ? document.querySelectorAll("select[name='default_option'")[1].value
          : "";
        this.addData.option3 = document.querySelectorAll(
          "select[name='default_option'"
        )[2]
          ? document.querySelectorAll("select[name='default_option'")[2].value
          : "";
        this.addData.option4 = document.querySelectorAll(
          "select[name='default_option'"
        )[3]
          ? document.querySelectorAll("select[name='default_option'")[3].value
          : "";
        this.addData.option5 = document.querySelectorAll(
          "select[name='default_option'"
        )[4]
          ? document.querySelectorAll("select[name='default_option'")[4].value
          : "";
        this.addData.ea = "1";
        this.addData.consumer_price = data.consumer_price;
        this.addData.price = parseInt(data.price);
        this.addData.cart_suboptions = [];
        if (this.addData.option1) {
          this.addData.opt = this.addData.option1;
        }
        if (this.addData.option2) {
          this.addData.opt = `${this.addData.option1}|${this.addData.option2}`;
        }
        if (this.addData.option3) {
          this.addData.opt = `${this.addData.option1}|${this.addData.option2}|${this.addData.option3}`;
        }
        if (this.addData.option4) {
          this.addData.opt = `${this.addData.option1}|${this.addData.option2}|${this.addData.option3}|${this.addData.option4}`;
        }
        if (this.addData.option5) {
          this.addData.opt = `${this.addData.option1}|${this.addData.option2}|${this.addData.option3}|${this.addData.option4}|${this.addData.option5}`;
        }

        // 추가 옵션이 필수 라면 추가 상품을 선택 해주세요
        if (this.isRequried) {
          // 동일 옵션 존재 여부 확인
          try {
            await this.dupleCheck();
            this.$toast.default("필수 추가 상품을 선택 해주세요.");
          } catch (e) {
            this.$toast.default("동일한 옵션이 존재 합니다.");
            this.tmpOptData = this.addData.opt;

            //선택 옵션 데이터 초기화
            this.addData = {};
          }
        } else {
          // 추가 상품이 필수 가 아니면 orderData  에 추가
          try {
            await this.dupleCheck();
            this.$store.dispatch("shop/updateOptions", {
              type: "add",
              data: this.addData,
            });
          } catch (e) {
            this.$toast.default("동일한 옵션이 존재 합니다.");
          }
          this.tmpOptData = this.addData.opt;

          //선택 옵션 데이터 초기화
          this.addData = {};
        }
      }
      this.$refs.optionItem.reRender();
      this.$forceUpdate();
    },
    requriedItemCheck(event = null) {
      const subSel = [...document.querySelectorAll("select[name='subOption'")];
      let check = [];
      let isTrue = true;
      for (let sub of subSel) {
        if (sub.dataset.required.toLowerCase() === "y") {
          check = this.basket[this.basket.length - 1].cart_suboptions.filter(
            (csub) => csub.suboption_title === sub.dataset.title
          );
          if (check.length <= 0) {
            this.$toast.default(
              `'${sub.dataset.title}' 필수 추가 상품을 선택 해주세요`
            );
            if (event) {
              event.target.value = this.tmpName;
            }
            isTrue = false;
            break;
          }
        }
      }
      return isTrue;
    },
    dupleCheck() {
      return new Promise((resolve, reject) => {
        let option = "";
        this.basket.forEach((op) => {
          option = op.title1 && `${op.option1}`;
          if (option === this.addData.opt) reject();

          option = op.title2 && `${op.option1}|${op.option2}`;
          if (option === this.addData.opt) reject();

          option = op.title3 && `${op.option1}|${op.option2}|${op.option3}`;
          if (option === this.addData.opt) reject();

          option =
            op.title4 &&
            `${op.option1}|${op.option2}|${op.option3}|${op.option4}`;
          if (option === this.addData.opt) reject();

          option =
            op.title5 &&
            `${op.option1}|${op.option2}|${op.option3}|${op.option4}|${op.option5}`;
          if (option === this.addData.opt) reject();
        });
        resolve();
      });
    },
    async getOptionalChanges() {
      await this.$store.dispatch("order/getOptionalChanges", {
        no: this.no,
      });
    },
    setData() {
      let formData = new FormData();
      let shipping_prepay_info =
        this.shippingSet[0].prepay_info === "all" ||
        this.shippingSet[0].prepay_info === "delivery"
          ? "delivery"
          : this.shippingSet[0].prepay_info;

      formData.append("goodsSeq", this.goodsSeq);
      formData.append("store_sel", ""); //  고정값
      formData.append("shipping_method", this.shippingSet[0].shipping_set_seq);
      formData.append("hop_select_date", ""); // 고정값
      formData.append("shipping_prepay_info", shipping_prepay_info);
      formData.append("option_select_goods_seq", this.goodsSeq);
      formData.append(
        "option_select_provider_seq",
        this.goodsInfo.provider_seq
      );
      formData.append("gl_option_select_ver", "0.1"); //고정값
      formData.append("use_add_action_button", "y"); //고정값
      formData.append("viewOptions[]", ""); //고정값
      formData.append("viewSuboption[]", ""); //고정값
      if (this.isRequried) {
        this.subOptions.map((sub) => {
          if (sub[0].sub_required.toLowerCase() === "y") {
            formData.append(
              "suboption_title_required[]",
              sub[0].suboption_title
            );
          }
        });
      }
      const inputELMain = document.querySelectorAll(".optionPrice");
      const inputELSub = document.querySelectorAll(".suboptionPrice");
      let titleArray = [];
      let mainOpt = [];
      let isNull = true;
      inputELMain.forEach((el, i) => {
        titleArray = el.dataset.title.split("|");
        mainOpt = el.dataset.option.split("|");
        if (titleArray[0] !== "null") {
          isNull = false;
          titleArray.forEach((title, j) => {
            if (mainOpt[j]) {
              formData.append(`option[${i}][${j}]`, mainOpt[j]);
              formData.append(`optionTitle[${i}][${j}]`, title);
            }
          });
          formData.append(`optionEa[${i}]`, el.value);
          inputELSub.forEach((sub, k) => {
            if (parseInt(sub.dataset.parent_idx) === i) {
              formData.append(
                `suboption[${i}][${parseInt(sub.dataset.idx)}]`,
                sub.dataset.sub_option
              );
              formData.append(
                `suboptionTitle[${i}][${parseInt(sub.dataset.idx)}]`,
                sub.dataset.sub_title
              );
              formData.append(
                `suboptionEa[${i}][${parseInt(sub.dataset.idx)}]`,
                sub.value
              );
            }
          });
        } else {
          inputELSub.forEach((sub, k) => {
            formData.append(
              `suboption[${i}][${parseInt(sub.dataset.idx)}]`,
              sub.dataset.sub_option
            );
            formData.append(
              `suboptionTitle[${i}][${parseInt(sub.dataset.idx)}]`,
              sub.dataset.sub_title
            );
            formData.append(
              `suboptionEa[${i}][${parseInt(sub.dataset.idx)}]`,
              sub.value
            );
          });
        }
      });

      if (isNull) {
        formData.append(`option[0][0]`, "");
        formData.append(`optionTitle[0][0]`, "");
        formData.append(`optionEa[0]`, inputELMain[0].value);
      }
      formData.append("review_seq", this.reviewSeq); //이 값이 있으면 리뷰타고 들어 온것

      // for (let pair of formData.entries()) {
      //   console.log(`${pair[0]}: ${pair[1]}`);
      // }

      return formData;
    },
    async setAdd() {
      if (!this.needsLogin()) {
        return false;
      }
      if (this.basket.length <= 0) {
        this.$toast.default("상품을 선택해주세요.");
        return true;
      }
      if (!this.requriedItemCheck()) {
        return false;
      }
      await this.$store.dispatch("shop/setAdd", this.setData());
      if (!this.result) {
        this.$toast.default(this.msg);
      } else {
        this.$confirm("장바구니에 담았습니다. \n장바구니로 이동하시겠습니까?")
          .then(() => {
            this.$router.push("/mypage/my_cart");
          })
          .catch(() => {
            return false;
          });
      }
    },
    async purchase() {
      if (!this.needsLogin()) {
        return false;
      }
      if (this.basket.length <= 0) {
        this.$toast.default("상품을 선택해주세요.");
        return true;
      }
      if (!this.requriedItemCheck()) {
        return false;
      }
      await this.$store.dispatch("order/setSettle", this.setData());
      console.log(this.orderResult);
      if (!this.orderResult) {
        this.$toast.default(this.orderMsg);
      } else {
        this.$router.push(
          `/order_payment/${this.id}/${this.goodsSeq}/direct${
            this.reviewSeq ? `?review_seq=${this.reviewSeq}` : ""
          }`
        );
      }
    },
    setTotal(str) {
      this.totalCost =
        parseInt(str) +
        (parseInt(this.overShippingCost) > 0 &&
        parseInt(str) >= parseInt(this.overShippingCost)
          ? 0
          : this.shippingCost);

      this.diffGoodsCost = parseInt(str);
      this.goodsCost = this.won(parseInt(str));
    },
    getMenu(num) {
      if (parseInt(num) == 1) {
        this.goMainList();
        return false;
      }
      if (parseInt(num) == 3) {
        return false;
      }
    },
  },
};
</script>

<style lang="scss">
.sold_out {
  width: calc(60% - 8px);
  margin-right: 8px;
  border: 1px solid #cdcdcd;
  color: black;
  background: #cdcdcd;
}
.location_title {
  font-weight: 600;
  margin-top: 5px;
  color: #000;
}
.shape_red::before,
.shape_yellow::before {
  content: "";
  display: inline-block;
  width: 12px;
  height: 12px;
  margin: 0 2px;
  vertical-align: middle;
  border-radius: 50%;
}
.shape_yellow::before {
  background: $primary-color;
}
.shape_red::before {
  background: red;
}
</style>
